@import "./theme.scss";

#root > .container {
  padding-top: $spacer;
  padding-bottom: max($navbar-brand-height, $nav-link-height) + 2 * $spacer;
}

html {
  font-family: $font-family;
}

.row {
  margin-bottom: 1rem;
}

h2 {
  margin-top: 2rem;
}

.player-container {
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  // padding: 0 !important;
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: 0 0.05rem 0.5rem 0 rgba(black, 0.5);

  > * {
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  .player {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;

    video {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .player-option-top,
  .player-option-bottom {
    position: absolute;
    right: 3rem;
    height: calc(50% - 4rem);
    aspect-ratio: 1 / 1;
    background: rgba(grey, 0.5);
    color: white;
    outline: 0;
    border: 0;
    border-radius: 2rem;
    box-shadow: 0 0 0.5rem 0 rgba(black, 0.5);
    font-size: 3rem;
    font-weight: bold;
    padding: 1rem;

    &:hover {
      background: rgba(lightgrey, 0.5);
    }

    &.player-option-top {
      top: 3rem;
    }

    &.player-option-bottom {
      bottom: 3rem;
    }
  }

  .player-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    border-radius: 0;
    display: flex;
    align-items: center;
    background: rgba(100, 100, 100, 0.5);
    color: white;
    box-shadow: 0 0 0.5rem 0 rgba(black, 0.5);
    padding: 0.75rem;
    gap: 0.75rem;

    .player-play-button {
      flex: 0;
      background: 0;
      outline: 0;
      border: 0;
      color: white;
    }

    .player-progress-bar {
      flex: 1;
      background: rgba(white, 0.7);
      height: 0.4rem;
      border-radius: 0.2rem;

      &::-moz-progress-bar,
      &::-webkit-progress-value {
        background: white;
        border-radius: 0.2rem;
      }
    }

    .player-progress-time {
      font-size: 0.75rem;
    }
  }

  .player-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10%;
    aspect-ratio: 1 / 1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: rgba(grey, 0.75);
    color: white;
    outline: 0;
    border: 0;
    box-shadow: 0 0 0.5rem 0 rgba(black, 0.5);
  }

  &.playing:not(:hover),
  &.playing.idle {
    .player-big-play-button:not(:hover),
    .player-controls:not(:hover) {
      display: none;
    }
  }
}

.universty-banner {
  .card-img {
    aspect-ratio: 42 / 9;
    object-fit: cover;
    filter: blur(0.1rem);
  }

  .card-img-overlay {
    background: rgba(black, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;

    .btn {
      align-self: flex-start;
      justify-self: end;
    }
  }
}
